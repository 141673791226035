<template>
  <div>
    <b-row v-if="items.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="items"
            :fields="fields"
            @row-dblclicked="editForm"
            @row-clicked="clicked"
            head-row-variant="primary"
            @row-contextmenu="handleContextMenuEvent">
            <template #cell(checkbox)="row">
              <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
              </b-form-checkbox>
            </template>
            <template #cell(status)="data">
              {{ data.item.status == 1 ? "Не блокирован" : "Блокирован" }}
            </template>
            <template #cell(position_id)="data">
              <!-- <router-link :to="{ name: 'chapter', query: {access_id: data.item.employee_group.position.id} }"> -->
              <div @dblclick="getPosition_id(data)" style="color: blue" select-variant="secondary">(***)</div>
              <!-- </router-link> -->
            </template>
          </b-table>
          <vue-context ref="menu" class="menu">
            <li>
              <b-link class="d-flex align-items-center" @click="getBlock()">
                <feather-icon icon="LockIcon" size="16" />
                <span class="ml-75">{{ status }}</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VueContext,
  },
  props: ["items"],
  data() {
    return {
      employeStatus: {
        status: null,
      },
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "first_name",
          label: "Имя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "last_name",
          label: "Фамилия",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "phone",
          label: "Номер телефона",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "employee_group.name",
          label: "Отдел",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "employee_group.position.name",
          label: "Должность",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "position_id",
          label: "Разделы",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dop_phone",
          label: "Доп. номер тел.",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    resizeable();
    this.$emit("refresh");
  },
  computed: {
    status() {
      return this.employeStatus.status == 1 ? "Не блокирован" : "Блокирован";
    },
  },
  methods: {
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.employeStatus = item;
    },
    editForm(id) {
      this.employeStatus = id;
      this.$emit("editForm", id.id);
    },
    getBlock() {
      this.$http
        .post(`employee/status`, {
          employee_id: this.employeStatus.id,
          status: this.employeStatus.status == 1 ? 0 : 1,
        })
        .then(() => {
          this.$emit("update");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Успешно изменено`,
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    getPosition_id(data) {
      this.$router.push({
        name: "chapter",
        query: { access_id: data.item.employee_group.position.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Разделы " + data.item.employee_group.position.id,
        `/position/accesses`,
        data.item.employee_group.position.id,
      ]);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      this.employeStatus = item;
      cellSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
